import type { ComponentType } from 'react'
import type { IEmbedTwitter } from '../../atoms/EmbedTwitter'
import type { IHtmlBlock } from '../../atoms/HtmlBlock'
import type { IFinalityStatus } from '../../../types/cmp'
import type { IEmbedInstagram } from '../../atoms/EmbedInstagram'
import type { IEmbedFacebook } from '../../atoms/EmbedFacebook'
import type { IEmbedYoutube } from '../../atoms/EmbedYoutube'
import type { IEmbedBeop } from '../../atoms/EmbedBeop'
import dynamic from 'next/dynamic'
import { useAmp } from 'next/amp'
import { useCMP } from '../../../hook/useCMP'

// List of embed that manage consent themself
const EXCLUDE_CONSENT_LIST = ['twitter', 'twitter-moment']
export interface IEmbed {
  amp?: 'hide' | 'show'
  id: string
  requiredConsents?: IFinalityStatus[]
  type: string
  info: string
  wordingConsent?: {
    text: string
    label: string
  }
  link?: string
  params: { [key: string]: string }
}

// prettier-ignore
const embedComponentPerType: { [key: string]: ComponentType<IEmbedTwitter | IEmbedInstagram | IEmbedFacebook | IEmbedYoutube | IEmbedBeop | IHtmlBlock> } = {
  'html': dynamic(() => import('../../atoms/HtmlBlock').then((module) => module.HtmlBlock)),
  twitter: dynamic(() => import('../../atoms/EmbedTwitter').then((module) => module.EmbedTwitter)),
  'twitter-moment': dynamic(() => import('../../atoms/EmbedTwitter').then((module) => module.EmbedTwitter)),
  'instagram': dynamic(() => import('../../atoms/EmbedInstagram').then((module) => module.EmbedInstagram)),
  'facebook':dynamic(() => import('../../atoms/EmbedFacebook').then((module) => module.EmbedFacebook)),
  'youtube':dynamic(() => import('../../atoms/EmbedYoutube').then((module) => module.EmbedYoutube)),
  'beop': dynamic(() => import('../../atoms/EmbedBeop').then((module) => module.EmbedBeop)),
  'opta': dynamic(() => import('../../atoms/EmbedOpta').then((module) => module.EmbedOpta)),
}

export function Embed({
  amp,
  requiredConsents,
  wordingConsent,
  type,
  ...restProps
}: IEmbed): JSX.Element {
  const { hasCorrectConsent } = useCMP(requiredConsents)
  const isAmp = useAmp()

  if (
    (isAmp && amp === 'hide') ||
    (!isAmp && !hasCorrectConsent && !EXCLUDE_CONSENT_LIST.includes(type))
  ) {
    return null
  }

  const EmbedComponent = embedComponentPerType[type] ?? embedComponentPerType['html']

  return EmbedComponent ? (
    <EmbedComponent type={type} wordingConsent={wordingConsent} {...restProps} />
  ) : null
}
