export function VideoIcon({
  color = 'var(--color-white)',
  secondary = 'var(--color-focusBlue)',
  size = 15
}: {
  color?: string
  secondary?: string
  size?: number
}): JSX.Element {
  return (
    <svg width={size} height={size} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 12V1L14 8L1 14V7Z" stroke={color} strokeWidth="2" strokeLinejoin="bevel" />
      <rect x="0" y="4" width="2" height="6" fill={secondary} />
    </svg>
  )
}
