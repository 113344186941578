import type { Block } from '../../../types/block'
import { mixins } from '../../../styles'

export interface IQuote extends Block {
  text: string
  author: string
}

export function Quote({ text, author, 'data-module': dataModule }: IQuote): JSX.Element {
  return (
    <>
      <h2 className="Quote" data-module={dataModule}>
        <blockquote className="Quote__Text">{text}</blockquote>
        <small className="Quote__Author">{author}</small>
      </h2>

      <style jsx>{`
        .Quote {
          margin: 0 50px 50px 50px;
          padding-left: 26px;
          background-image: linear-gradient(
            var(--color-focusBlue) calc(100% - 10px),
            var(--color-deepBlue) 10px
          );
          background-size: 6px 100%;
          background-position: left 0;
          background-repeat: no-repeat;
        }

        .Quote__Text {
          margin: 0 0 12px 0;
          color: var(--color-focusBlue);
          ${mixins.fonts.article.quote}
        }

        @media ${mixins.mediaQuery.desktop} {
          .Quote__Text {
            font-size: 38px;
          }
        }

        .Quote__Author {
          margin: 0;
          color: var(--color-midBlue);
          ${mixins.fonts.base.medium}
        }

        .Quote__Text:before {
          content: '“';
        }

        .Quote__Text:after {
          content: '”';
        }
      `}</style>
    </>
  )
}
