export type IDot = {
  width?: number
  height?: number
  color?: string
}

export function Dot({
  width = 6.15,
  height = 6.15,
  color = 'var(--color-focusBlue)',
}: IDot): JSX.Element {
  return (
    <>
      <span className="Dot" />

      <style jsx>
        {`
          .Dot {
            height: ${height}px;
            width: ${width}px;
            background-color: ${color};
            border-radius: 50%;
            display: inline-block;
          }
        `}
      </style>
    </>
  )
}
