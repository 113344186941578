import type { BlockData } from '../../templates/Block'
import { useContext } from 'react'
import { theme } from '../../../styles'
import { Headline } from '../../atoms/Headline'
import { Topic } from '../../atoms/Topic'
import { MoreLinkArticle } from '../../molecules/MoreLinkArticle'
import { StackTags } from '../../molecules/StackTags'
import { TagCommanderContext } from '../../../contexts/tagcommander'

export function ArticleBounceFolder({
  title,
  tagList,
  elementList,
  topicList,
}: BlockData<'article-bounce-folder'>): JSX.Element {
  const { hit } = useContext(TagCommanderContext)

  const handleOnClick = () => {
    hit(
      {
        screen_clickableElementName: `bloc_topic-list-bounce-folder`,
      },
      { isClickEvent: true },
    )
  }

  return (
    <>
      <div className="ArticleBounceFolder">
        <Headline htmlText={title} />
        <StackTags data-testid="ArticleBounceFolder__Tag_List" tagList={tagList} />

        {topicList?.length ? (
          <ul
            data-testid="ArticleBounceFolder__Topic_List"
            className="ArticleBounceFolder__List_Topic flex flex-column"
          >
            {topicList.map((topic) => (
              <li className="ArticleBounceFolder__List_Topic_Item" key={topic.link}>
                <Topic {...topic} onClick={handleOnClick} />
              </li>
            ))}
          </ul>
        ) : null}

        <MoreLinkArticle elementList={elementList} />
      </div>

      <style jsx>{`
        .ArticleBounceFolder {
          margin: 0 0 90px;
        }

        .ArticleBounceFolder :global(.Headline) {
          margin-bottom: 40px;
        }

        .ArticleBounceFolder :global(.StackTags) {
          margin: 0 ${theme.block.marginLR}px 50px;
        }

        .ArticleBounceFolder__List_Topic {
          list-style: none;
          padding: 0;
          margin-bottom: 50px;
        }

        .ArticleBounceFolder__List_Topic_Item {
          margin-bottom: 2px;
        }

        .ArticleBounceFolder__List_Topic_Item:last-child {
          margin-bottom: 0;
        }
      `}</style>
    </>
  )
}
