import { useContext } from 'react'
import { useAmp } from 'next/amp'

import { RESEAUX_SOCIAUX } from '../../../constants/cmp'
import { isServer } from '../../../helpers/client'
import { TagCommanderContext } from '../../../contexts/tagcommander'
import { useCMP } from '../../../hook/useCMP'
import { SVGIcon } from '../../atoms/SVGIcon'

export interface ISocialNetworkSharing {
  bgColor?: string
  color?: string
  logoColor?: string
  logoColorSecondary?: string
  title?: string
  tmsId?: string
}

export function SocialNetworkSharing(props: ISocialNetworkSharing): JSX.Element {
  const isAmp = useAmp()
  const { hasCorrectConsent } = useCMP([RESEAUX_SOCIAUX])
  if (isAmp || (!isServer() && !navigator.share) || !hasCorrectConsent) {
    return null
  }

  return <SocialNetworkSharingComponent {...props} />
}

export function SocialNetworkSharingComponent({
  bgColor,
  color,
  logoColor,
  logoColorSecondary,
  title,
  tmsId = 'bouton_partage',
}: ISocialNetworkSharing): JSX.Element {
  const { hit } = useContext(TagCommanderContext)

  async function share() {
    hit(
      {
        screen_clickableElementName: tmsId,
      },
      { isClickEvent: true },
    )

    if (navigator.share) {
      try {
        await navigator.share({
          title: document.title,
          url: window.location.href,
        })
      } catch (e) {
        // Navigator throw an error when canceled
        return
      }
    } else {
      // TO DO à définir quoi faire en desktop sur chrome car navigator.share ne fonctionne pas sur chrome
      return
    }
  }

  return (
    <>
      <div className="SocialNetworkSharing flex justify-center items-center" onClick={share}>
        {title && <span>{title}</span>}
        <div className="SocialNetworkSharing__ContentIcon flex">
          <SVGIcon
            name="share"
            primaryColor={logoColor}
            secondaryColor={logoColorSecondary || logoColor}
            size="24px"
          />
        </div>
      </div>

      <style jsx>{`
        .SocialNetworkSharing {
          background-color: ${bgColor ? bgColor : 'var(--color-white)'};
        }

        .SocialNetworkSharing span {
          color: ${color ? color : 'var(--color-deepBlue)'};
        }
      `}</style>
      <style jsx>{`
        .SocialNetworkSharing {
          align-content: center;
          height: 5em;
          padding-bottom: 0.9em;
          margin-bottom: 20px;
          width: 100%;
          cursor: pointer;
        }

        .SocialNetworkSharing span {
          font-size: 16px;
          font-weight: 700;
        }

        .SocialNetworkSharing__ContentIcon {
          margin-left: 0.7em;
        }
      `}</style>
    </>
  )
}
