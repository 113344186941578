import type { Sponsor } from '@etf1-interne/tf1info_types_news/generics/sponsor'
import type { ArticleHeaderCredit } from '@etf1-interne/tf1info_types_news/generics/article'

import { mixins, theme } from '../../../styles'
import { ArticleHeaderSign } from '../../atoms/ArticleHeaderSign'
import { ArticleHeaderTitle } from '../../molecules/ArticleHeaderTitle'
import { ArticleSponsoBanner } from '../../molecules/ArticleSponsoBanner'
export interface IArticleHeaderX {
  children: JSX.Element
  credit?: ArticleHeaderCredit
  sponsor: Sponsor | null
  format: {
    name: string
    label: string
  }
  title: string
  hasMultiLive?: boolean
}

export function ArticleHeaderX({
  children,
  credit,
  sponsor,
  format,
  title,
  hasMultiLive,
}: IArticleHeaderX): JSX.Element {
  return (
    <>
      <div className="ArticleHeaderImage__Wrapper">
        <div className="ArticleHeaderImage__Content flex flex-column">
          <div className="ArticleHeaderImage__Wrapper_Title">
            <ArticleHeaderTitle
              format={format}
              title={title}
              hasMultiLive={hasMultiLive}
              hasSponsor={!!sponsor}
            />
            <ArticleHeaderSign credit={credit} hasSponsor={!!sponsor} />
            {sponsor && <ArticleSponsoBanner {...sponsor} />}
          </div>
          {children ? <div className="ArticleHeaderImage__Wrapper_Media">{children}</div> : null}
        </div>
      </div>

      <style jsx>{`
        @media ${mixins.mediaQuery.tabletPaysage} {
          .ArticleHeaderImage__Content {
            flex-direction: ${children ? 'row-reverse' : 'column'};
          }
        }
      `}</style>
      <style jsx>{`
        .ArticleHeaderImage__Wrapper {
          background: ${sponsor ? theme.cssVars.lightBlue : theme.cssVars.deepBlue};
        }

        .ArticleHeaderImage__Wrapper :global(.ArticleHeaderImage__Source),
        .ArticleHeaderImage__Wrapper :global(.ArticleHeaderCopyright__Source > span) {
          color: ${sponsor ? theme.cssVars.deepBlue : theme.cssVars.lightBlue};
        }

        .ArticleHeaderImage__Content {
          padding: 30px 0px;
          max-width: ${theme.layout.maxWidth}px;
          margin: auto;
        }

        .ArticleHeaderImage__Wrapper_Title {
          padding: 0 ${theme.block.marginLR}px;
        }

        .ArticleHeaderImage__Content :global(.ArticleHeaderCopyright) {
          padding: 0 ${theme.block.marginLR}px;
        }

        @media ${mixins.mediaQuery.tablet} {
          .ArticleHeaderImage__Content {
            padding: 30px ${theme.block.marginLR}px;
          }

          .ArticleHeaderImage__Content :global(.ArticleHeaderCopyright) {
            padding: 0;
          }

          .ArticleHeaderImage__Wrapper_Title {
            padding: 0;
          }
        }

        @media ${mixins.mediaQuery.tabletPaysage} {
          .ArticleHeaderImage__Wrapper_Media {
            min-width: 500px;
            max-width: 500px;
            margin-right: 48px;
          }
        }

        @media ${mixins.mediaQuery.desktop} {
          .ArticleHeaderImage__Wrapper_Media {
            min-width: 50%;
            max-width: 50%;
          }
        }
      `}</style>
    </>
  )
}
