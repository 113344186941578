import type { IPictureElementList } from '../../atoms/PictureElementList'
import { PictureElementList } from '../PictureElementList'
import { theme } from '../../../styles'

export interface IRoundImage {
  pictures: IPictureElementList
}

export function RoundImage({ pictures }: IRoundImage): JSX.Element {
  return (
    <>
      <div className="RoundImage">
        <PictureElementList {...pictures} />
      </div>

      <style jsx>{`
        .RoundImage {
          width: 100%;
          height: 100%;
          position: relative;
          padding: 5px;

          border-radius: 50%;
          border: 2px solid ${theme.colors.deepBlue};
          border-top: 2px solid ${theme.colors.focusBlue};
          transform: rotate(45deg);
          overflow: hidden;
        }

        .RoundImage :global(> picture > img),
        .RoundImage :global(.PictureElementList__PlaceHolder) {
          top: 2px;
          left: 2px;
        }

        .RoundImage :global(img),
        .RoundImage :global(.PictureElementList__PlaceHolder) {
          position: absolute;
          width: calc(100% - 4px);
          height: calc(100% - 4px);
          display: flex;

          transform: rotate(-45deg);
        }

        .RoundImage :global(img) {
          border-radius: 50%;
        }

        .RoundImage :global(.PictureElementList__PlaceHolder) {
          border-radius: 50%;
        }
      `}</style>
    </>
  )
}
