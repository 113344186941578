import type { ISponsorBanner } from '../../molecules/SponsorBanner'

import { useContext } from 'react'

import { Topic } from '../../atoms/Topic'
import { SponsorBanner } from '../../molecules/SponsorBanner'
import { mixins, theme } from '../../../styles'
import { TagCommanderContext } from '../../../contexts/tagcommander'

export interface IArticleTopic {
  topic: {
    title: string
    color: string
    link: string
  }
  sponsor?: ISponsorBanner
  title?: string
}

export function ArticleTopic({ title, topic, sponsor }: IArticleTopic): JSX.Element {
  const { hit } = useContext(TagCommanderContext)

  const handleOnClick = () => {
    hit(
      {
        screen_clickableElementName: `bloc_article-banner`,
      },
      { isClickEvent: true },
    )
  }

  return (
    <>
      <div className="ArticleTopic">
        {topic && (
          <Topic
            {...topic}
            title={
              <>
                <p style={{ fontWeight: 400 }}>{title}</p>
                <p style={{ fontWeight: 800 }}>{topic.title}</p>
              </>
            }
            icon="directory"
            onClick={handleOnClick}
          />
        )}
        {sponsor && (
          <SponsorBanner
            title={sponsor.title}
            link={sponsor.link}
            pictures={sponsor.pictures}
            type={sponsor.type}
          />
        )}
      </div>

      <style jsx>{`
        .ArticleTopic {
          margin: 0 20px 50px;
        }

        .ArticleTopic :global(.Topic) {
          min-height: 64px;
          border-radius: ${sponsor ? '5px 5px 0 0' : '5px'};
          padding: 15px 15px;
        }

        .ArticleTopic :global(.SponsorBanner) {
          border-radius: 0 0 5px 5px;
        }

        @media ${mixins.mediaQuery.tablet} {
          .ArticleTopic :global(.Topic) {
            max-width: ${theme.layout.maxWidth}px;
            margin: auto;
          }
        }
      `}</style>
    </>
  )
}
