import type { IPicture } from '../Picture'
import { mixins, theme } from '../../../styles'
import { Link } from '../../atoms/Link'
import { PictureElementList } from '../../atoms/PictureElementList'

export interface IArticleSponsoBanner {
  link: string
  pictures: IPicture
  title: string
  type?: string
  withPreload?: boolean
}

export function ArticleSponsoBanner({
  link,
  pictures,
  title,
  type,
  withPreload,
}: IArticleSponsoBanner): JSX.Element {
  return (
    <>
      <div className="ArticleSponsoBanner flex items-center justify-start">
        <div className="ArticleSponsoBanner__Content flex items-center">
          <span className="ArticleSponsoBanner__Label">{title}</span>
          {pictures && (
            <Link url={link} type={type}>
              <PictureElementList
                alt={pictures.alt}
                elementList={pictures.elementList}
                lazyload={pictures.lazyload}
                placeholderTheme="light"
                withPreload={withPreload}
                layout="intrinsic"
              />
            </Link>
          )}
        </div>
      </div>

      <style jsx>{`
        .ArticleSponsoBanner {
          background-color: transparent;
          margin-bottom: 10px;
        }

        .ArticleSponsoBanner__Content {
          gap: 10px;
        }

        .ArticleSponsoBanner :global(img),
        .ArticleSponsoBanner :global(amp-img) {
          height: 48px;
        }

        .ArticleSponsoBanner__Label {
          font-size: 11px;
          font-weight: 600;
          color: ${theme.cssVars.deepBlue};
          margin-bottom: 5px;
        }

        @media ${mixins.mediaQuery.tabletPaysage} {
          .ArticleSponsoBanner__Label {
            font-size: 14px;
            font-weight: 400;
          }
        }
      `}</style>
    </>
  )
}
