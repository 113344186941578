// Segments are valuable user informations for player ads. Are you male/female, young/old, do you like sports or culture etc..
// TF1 player needs them formatted as { MIC_123: 'Y', MIC_456: 'Y' }
// Booleans are overrated anyway, enum ['Y'] with a single possible value is the future

export const getMediarithmicsObject = () => {
    const obj = {}

    try {
      const mics_segments = JSON.parse(String(localStorage.getItem('mics_segments'))) || null
      const mics_sgmts = JSON.parse(String(localStorage.getItem('mics_sgmts'))) || null

      if (mics_segments?.segments) {
        mics_segments.segments.map((item) => {
          if (item.name) {
            const value = item.name.split('=')
            obj[value[0]] = value[1]
          }
        })
      }

      const lciEdgeKey = Object.keys(mics_sgmts)[0]; // lciEdgeKey should be lci_edge
      if (mics_sgmts && mics_sgmts[lciEdgeKey]) {
        mics_sgmts[lciEdgeKey].map((item) => (obj[`MIC_${item}`] = 'Y'))
      }
    } catch (e) {
      console.error('Error retrieving mediaRithmics object', e)
    }

    return obj
  }
