import type { IPicture } from '../../../types/pictures'

import { useContext } from 'react'
import { theme, mixins } from '../../../styles'
import { Link } from '../../atoms/Link'
import { TagCommanderContext } from '../../../contexts/tagcommander'
import { PictureElementList } from '../../atoms/PictureElementList'
import { Icon } from '../../atoms/Icon'
import { SVGIcon } from '../../atoms/SVGIcon'

export interface IReadMoreArticle {
  title: string
  elementList: {
    link: string
    description: string
    hasMultiLive: boolean
    pictures: IPicture
    icon?: string
  }[]
}

export function ReadMoreArticle({ title, elementList }: IReadMoreArticle): JSX.Element {
  const { hit } = useContext(TagCommanderContext)

  const handleOnClick = () => {
    hit(
      {
        screen_clickableElementName: `bloc_lire-aussi`,
      },
      { isClickEvent: true },
    )
  }

  return elementList?.length ? (
    <>
      <ul className="ReadMoreArticle__List flex flex-column">
        {elementList.map(({ description, link, hasMultiLive, pictures, icon }) => (
          <li key={link}>
            <Link
              className="flex"
              url={link}
              color={theme.cssVars.deepBlue}
              onClick={handleOnClick}
            >
              {pictures && (
                <figure className="ReadMoreArticle__List__Item__Picture">
                  {icon && (
                    <Icon
                      name="play-circle"
                      className="ReadMoreArticle__List__Item__Play"
                      secondaryColor={theme.cssVars.white}
                    />
                  )}
                  <PictureElementList {...pictures} alt={pictures?.caption || title} />
                </figure>
              )}
              <div className="ReadMoreArticle__Content">
                <p className="ReadMoreArticle__Title">{title}</p>
                <p>
                  {hasMultiLive && <SVGIcon name="direct" size={16} />}
                  {description}
                </p>
              </div>
            </Link>
          </li>
        ))}
      </ul>

      <style jsx>{`
        .ReadMoreArticle__List {
          background-color: ${theme.cssVars.lightBlue};
          padding: 30px;
          border-radius: 4px;
          margin: 0 0 30px;
          list-style: none;
          gap: 10px;
        }

        .ReadMoreArticle__List .ReadMoreArticle__Title {
          color: ${theme.cssVars.focusBlue};
          font-family: ${theme.cssVars.overpass};
          font-weight: 900;
          font-size: 17px;
          text-transform: uppercase;
          margin: 0 0 5px;
        }

        .ReadMoreArticle__List :global(.Link) {
          gap: 20px;
          width: 100%;
          height: 100%;
          font-size: 17px;
          font-weight: 800;
          margin: 0;
        }

        .ReadMoreArticle__List .ReadMoreArticle__List__Item__Picture {
          position: relative;
          height: fit-content;
        }

        .ReadMoreArticle__List
          .ReadMoreArticle__List__Item__Picture
          :global(.ReadMoreArticle__List__Item__Play) {
          position: absolute;
          bottom: 0.7em;
          left: 0.5em;
          z-index: 2;
        }

        :global(.ReadMoreArticle__List .Link img),
        :global(.ReadMoreArticle__List .Link amp-img) {
          height: 120px;
          width: 120px;
        }

        :global(.ReadMoreArticle__List .ReadMoreArticle__Content svg) {
          margin-right: 5px;
        }

        @media ${mixins.mediaQuery.tablet} {
          .ReadMoreArticle__List .ReadMoreArticle__Title {
            font-size: 20px;
          }

          .ReadMoreArticle__List :global(.Link) {
            font-size: 22px;
          }

          .ReadMoreArticle__List {
            margin: 0 ${theme.block.marginLR}px 50px;
          }
        }
      `}</style>
    </>
  ) : null
}
