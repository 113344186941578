import type { ReactNode } from 'react'
import { memo } from 'react'
import { theme } from '../../../styles'
import { LciLogo } from '../../atoms/Icon/LciLogo'
import { TF1Logo } from '../../atoms/Icon/TF1Logo'

export interface IArticleHeaderCopyright {
  copyright?: string
  prefix?: ReactNode
  channel?: string
  hasSponsor?: boolean
}

function ArticleHeaderCopyrightComponent({
  copyright,
  prefix,
  channel,
  hasSponsor,
}: IArticleHeaderCopyright): JSX.Element {
  const Logo = channel === 'lci' ? LciLogo : TF1Logo
  if (!copyright) {
    return null
  }

  return (
    <>
      <div className="ArticleHeaderCopyright flex">
        <p className={`ArticleHeaderCopyright__Source flex items-center`}>
          {prefix}
          <span>
            Source : <span className="ArticleHeaderCopyright__Source__Copyright">{copyright}</span>
          </span>
        </p>
        <Logo />
      </div>

      <style jsx>{`
        .ArticleHeaderCopyright__Source {
          margin-top: 12px;
        }
        .ArticleHeaderCopyright :global(> svg) {
          display: inline;
          margin-top: 12px;
        }

        .ArticleHeaderCopyright__Source > span {
          font-size: 13px;
          color: ${hasSponsor ? theme.cssVars.midBlue : theme.cssVars.white};
          padding-top: 2px;
        }

        .ArticleHeaderCopyright__Source__Copyright {
          color: ${theme.cssVars.midBlue};
          font-weight: 700;
        }

        .ArticleHeaderCopyright__Source :global(svg) {
          margin-right: 14px;
        }

        .ArticleHeaderCopyright :global(> svg) {
          width: 50px;
          height: 19px;
          margin-left: auto;
        }
      `}</style>
    </>
  )
}

export const ArticleHeaderCopyright = memo(ArticleHeaderCopyrightComponent)
