import type { ComponentType } from 'react'
import type { IPlayerComingNext } from '../../molecules/PlayerComingNext'
import type { IVideoExpired } from '../../atoms/VideoExpired'
import type { IPicture } from '../../../types/pictures'
import type { IAdCompanion } from '../../atoms/AdCompanion'
import type { AdCompanionData } from '../../../types/player'
import type { Video } from '../../../types/video'
import type { IArticleHeaderSign } from '../../atoms/ArticleHeaderSign'
import type { Sponsor } from '@etf1-interne/tf1info_types_news/generics/sponsor'

import { useState, useCallback } from 'react'
import css from 'styled-jsx/css'
import { useAmp } from 'next/amp'
import dynamic from 'next/dynamic'
import { ArticleHeaderCopyright } from '../../atoms/ArticleHeaderCopyright'
import { VideoIcon } from '../../atoms/Icon/Content/Video'
import { ArticleHeaderX } from '../ArticleHeaderX'
import { companionDataFormatter } from '../../../helpers/player'
import { ViewportCondition } from '../../atoms/ViewportCondition/ViewportCondition'
import { mixins, theme } from '../../../styles'

const PlayerComingNext: ComponentType<IPlayerComingNext> = dynamic(() =>
  import('../../molecules/PlayerComingNext').then((module) => module.PlayerComingNext),
)
const VideoExpired: ComponentType<IVideoExpired> = dynamic(() =>
  import('../../atoms/VideoExpired').then((module) => module.VideoExpired),
)
const AdCompanion: ComponentType<IAdCompanion> = dynamic(() =>
  import('../../atoms/AdCompanion').then((module) => module.AdCompanion),
)

export interface IArticleHeaderVideo {
  channel: string
  credit?: IArticleHeaderSign['credit']
  sponsor: Sponsor
  format: {
    name: string
    label: string
  }
  pictures: IPicture
  title: string
  video: Video
  hasMultiLive: boolean
}

const PLAYER_ID = 'article-header-video-coming-next-player-id'
const COMPANION_VISIBLE_VIEWPORTS = [theme.breakpoints.lg, theme.breakpoints.xl]

const getAmpStyles = () => {
  return css.resolve`
    @media ${mixins.mediaQuery.onlyPortrait} {
      :global(#body-header) {
        position: sticky;
        top: -300px;
        z-index: 1000;
      }

      :global(.ArticleHeaderTitle__Direct) {
        display: none;
      }

      :global(.ArticleHeaderImage__Wrapper_Title) {
        height: 240px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      :global(.ArticleHeaderImage__Wrapper_Title > .ArticleHeaderTitle__Wrapper > .Title) {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        line-clamp: 5;
        -webkit-box-orient: vertical;
      }

      :global(.ArticleHeaderImage__Wrapper_Media) {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        gap: 20px;
      }
    }
  `
}

export function ArticleHeaderVideo({
  channel,
  credit,
  sponsor,
  format,
  pictures,
  title,
  video,
  hasMultiLive,
}: IArticleHeaderVideo): JSX.Element {
  const [companionProps, setCompanionProps] = useState({})
  const isAmp = useAmp()

  const handlePlayerEvent = useCallback(
    function handlePlayerEventMemoized(data: AdCompanionData) {
      setCompanionProps(companionDataFormatter(data))
    },
    [setCompanionProps],
  )

  const ampStyle = isAmp ? getAmpStyles().styles : ''

  return (
    <>
      <div className="ArticleHeaderVideo">
        {!video?.isExpired && (
          <ViewportCondition visibleViewports={COMPANION_VISIBLE_VIEWPORTS}>
            <AdCompanion {...companionProps} />
          </ViewportCondition>
        )}
        <ArticleHeaderX {...{ format, credit, title, hasMultiLive, sponsor }}>
          <>
            {video?.isExpired ? (
              <VideoExpired {...{ video, pictures }} />
            ) : (
              <PlayerComingNext
                containerId={PLAYER_ID}
                videoId={video?.video_id}
                autoplay
                withPip
                advertisingTags={video?.specificFields?.advertisingTags}
                onAdCompanion={handlePlayerEvent}
                restriction={video?.restriction}
              />
            )}
            <ArticleHeaderCopyright
              copyright={video?.program?.name}
              prefix={<VideoIcon color={sponsor ? theme.cssVars.deepBlue : theme.cssVars.white} />}
              channel={channel}
              hasSponsor={!!sponsor}
            />
          </>
        </ArticleHeaderX>
      </div>
      <style jsx>{`
        .ArticleHeaderVideo {
          position: relative;
        }

        .ArticleHeaderVideo :global(.PlayerVideo) {
          z-index: 10;
        }
      `}</style>
      {ampStyle}
    </>
  )
}
