import { Title } from '../../atoms/Title'
import { SVGIcon } from '../../atoms/SVGIcon'
import { mixins, theme } from '../../../styles'

export interface IArticleHeaderTitle {
  format: {
    name: string
    label: string
  }
  title: string
  hasMultiLive?: boolean
  hasSponsor: boolean
}

export function ArticleHeaderTitle({
  format,
  title,
  hasMultiLive,
  hasSponsor,
}: IArticleHeaderTitle): JSX.Element {
  const label = format.name === 'live' ? '' : format.label

  return (
    <>
      <div className="ArticleHeaderTitle__Wrapper">
        {label ? (
          <span className="ArticleHeaderTitle__Format flex items-center">
            {label ? label : null}
          </span>
        ) : null}
        {hasMultiLive ? (
          <span className="ArticleHeaderTitle__Direct">
            <SVGIcon name="direct" />
          </span>
        ) : null}
        <Title dataTestId="txt-article-title">{title}</Title>
      </div>
      <style jsx>{`
        .ArticleHeaderTitle__Wrapper {
          color: ${hasSponsor ? theme.cssVars.deepBlue : theme.cssVars.white};
          margin-bottom: 25px;
        }

        .ArticleHeaderTitle__Wrapper :global(h1) {
          display: inline;
          font-size: 26px;
        }

        @media ${mixins.mediaQuery.tablet} {
          .ArticleHeaderTitle__Wrapper :global(h1) {
            font-size: 36px;
          }
        }

        @media ${mixins.mediaQuery.desktop} {
          .ArticleHeaderTitle__Wrapper :global(h1) {
            font-size: 42px;
            line-height: 56px;
          }
        }

        .ArticleHeaderTitle__Format {
          margin-bottom: 12px;
          text-transform: uppercase;
          color: ${hasSponsor ? theme.cssVars.midBlue : theme.cssVars.white};
          font-family: ${theme.cssVars.overpass};
          font-weight: 400;
          font-size: 13px;
        }

        .ArticleHeaderTitle__Format :global(svg) {
          margin-right: 5px;
          width: 50px;
          height: 19px;
        }

        .ArticleHeaderTitle__Direct :global(svg) {
          margin-right: 5px;
          width: 20px;
          height: 20px;
        }

        @media ${mixins.mediaQuery.tablet} {
          .ArticleHeaderTitle__Direct :global(svg) {
            width: 30px;
            height: 30px;
          }
        }
      `}</style>
    </>
  )
}
